import { Getter } from 'jotai';
import {
  ExtraInfoBaseTypes,
  IdToPatientExtraInfo,
  ExtraInfoFetchReturn,
  ExtraInfoMutationProps,
} from '../../ts';
import { patientsAtom } from '../../store/global/atoms';
import { chunkPatientsNames } from '../../utils';

type ExtraInfoFetch<T> = (
  patientsName: string[],
  page: number,
) => Promise<ExtraInfoFetchReturn<T> | string>;

export const createGetPatientsExtraInfoService =
  <T extends ExtraInfoBaseTypes>(fetchFunction: ExtraInfoFetch<T>) =>
  (get: Getter) =>
  async ({ chunkedPatientsNames }: ExtraInfoMutationProps): Promise<IdToPatientExtraInfo<T>> => {
    chunkedPatientsNames ??= chunkPatientsNames(get(patientsAtom));
    const extraInfoQueries = chunkedPatientsNames.map(chunk =>
      getPatientsExtraInfoHelper(chunk, fetchFunction),
    );
    const extraInfo = await Promise.allSettled(extraInfoQueries);
    const extraInfoNoErrors = extraInfo.map(result => {
      return result.status === 'fulfilled' ? result.value : [];
    });
    const flatExtraInfo = extraInfoNoErrors.flat();
    const idAssociatedExtraInfo = associatePatientIdWithExtraInfo(flatExtraInfo);
    return idAssociatedExtraInfo;
  };

const getPatientsExtraInfoHelper = async <T extends ExtraInfoBaseTypes>(
  patientsName: string[],
  fetchFunction: ExtraInfoFetch<T>,
): Promise<T[]> => {
  let page = 1;
  let dealsData: T[] = [];

  let continueLoop = true;
  while (continueLoop) {
    const response = await fetchFunction(patientsName, page);

    if (typeof response === 'string') throw new Error(response);

    dealsData = [...dealsData, ...response.data];
    page++;
    if (!response.info.more_records || page > 10) continueLoop = false;
  }

  return dealsData;
};

const associatePatientIdWithExtraInfo = <T extends ExtraInfoBaseTypes>(
  patientsExtraInfoArray: T[],
): IdToPatientExtraInfo<T> => {
  if (patientsExtraInfoArray.length === 0) return {};

  const idToExtraInfo: IdToPatientExtraInfo<T> = {};
  for (const entry of patientsExtraInfoArray) {
    if (idToExtraInfo[entry.patient.id] == null) {
      idToExtraInfo[entry.patient.id] = [entry];
      continue;
    }
    idToExtraInfo[entry.patient.id].push(entry);
  }

  return idToExtraInfo;
};
